import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, styles, Subheading } from '@storybook/design-system';
import GatsbyLink from '../basics/GatsbyLink';
import Logo from '../basics/Logo';
import LogoChroma from '../basics/LogoChroma';
import MailingListSignup from './MailingListSignup';

const { background, breakpoint, color, typography, pageMargins } = styles;

const FooterWrapper = styled.footer`
  background: ${background.app};
  padding: 52px 0;
  display: flex;
  color: ${color.darker};
  font-size: ${typography.size.s2}px;
  line-height: 20px;
  border-top: 1px solid ${color.mediumlight};
  margin-top: 80px;
  background: #F6F9FC !important;
`;

const FooterContent = styled.div`
  background: #F6F9FC !important;
  ${pageMargins}
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${breakpoint * 1.75}px) {
    flex-direction: row;
  }
`;

const FooterBlock = styled.div`
  width: auto;
  ${props =>
    !props.isLast &&
    `
    margin-bottom: 52px;
  `}

  @media (min-width: ${breakpoint * 1.75}px) {
    ${props =>
      !props.isLast &&
      `
      margin-bottom: 0;
      margin-right: 52px;
    `}
  }
`;

const FooterGuideBlock = styled(FooterBlock)`
  min-width: 158px;
`;

const FooterLogoBlock = styled.div`
  ${props => !props.isFirst && `margin-top: 38px;`}
`;

const FooterBlockContent = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const FooterBlockLink = styled(GatsbyLink)`
  && {
    display: block;
    color: ${color.darker};
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`;

const LogoWrapper = styled(Logo)`
  && {
    height: 24px;
    width: auto;
  }
`;

const LogoChromaWrapper = styled(LogoChroma)`
  && {
    height: 24px;
    width: auto;
  }
`;

const SubheadingWrapper = styled(Subheading)`
  color: ${color.mediumdark};
  font-size: ${typography.size.s1}px;
`;

const MailingListSignupWrapper = styled(MailingListSignup)`
  margin-top: 16px;
`;

const EmptyFooter = ({ guides, ...props }) => (
  <FooterWrapper {...props}>
  </FooterWrapper>
);
const Footer = ({ guides, ...props }) => (
  <FooterWrapper class="footer-container" className="footer-cottainer"  {...props}     >
    <FooterContent   >
      <FooterBlock>
        <SubheadingWrapper>Desarrollado por</SubheadingWrapper>
        <FooterLogoBlock isFirst class="footer-brand">
            <div class="copyright-text text-center">

		<p> <a href="http://www.softdelnoa.com.ar"> SOFT DEL NOA 2020 © </a> </p>
                <p class="social-links" >
		<a href="https://www.facebook.com/softdelnoa"  target="_blank" ><span class="camimacca"/>
			<i class="fa fa-facebook"></i><span/></a>
                <a href="https://twiter.com/softdelnoa" target="_blank" ><span class="camimacca"/><i class="fa fa-twitter"></i><span/></a>
                <a href="https://www.linkedin.com/company/329184" target="_blank"><span class="camimacca"/><i class="fa fa-linkedin"></i><span/></a>
                <a href="https://www.youtube.com/channel/UCjbd-KoNqu1lQ_BThG2GciA" target="_blank" ><span class="camimacca"/><i class="fa fa-youtube"></i><span/></a>
                <a href="https://www.instagram.com/softdelnoa/" target="_blank"><span class="camimacca"/><i class="fa fa-instagram"></i><span/></a>
                </p>
            </div>
	</FooterLogoBlock>

      </FooterBlock>

      <FooterGuideBlock>
        <SubheadingWrapper>Soluciones</SubheadingWrapper>

        <FooterBlockContent>
          {guides.edges.map(({ node: guideNode }) => (
            <FooterBlockLink tertiary to={guideNode.fields.slug} key={guideNode.frontmatter.title}>
              {guideNode.frontmatter.title}
            </FooterBlockLink>
          ))}
        </FooterBlockContent>
      </FooterGuideBlock>

      <FooterGuideBlock>
        <SubheadingWrapper>Link</SubheadingWrapper>

        <FooterBlockContent>
          <FooterBlockLink tertiary to="/caracteristicas">
     		Caracteristicas       
          </FooterBlockLink>

          <FooterBlockLink  to="/arquitectura" tertiary>
          	 Arquitectura
          </FooterBlockLink>

 <FooterBlockLink  href="servicios" tertiary>
          	 Servicios y Soporte
          </FooterBlockLink>

        </FooterBlockContent>
      </FooterGuideBlock>

      <FooterBlock isLast>
        <SubheadingWrapper>Contacto</SubheadingWrapper>

        <FooterBlockContent>
        
         <FooterBlockLink  to="/contacto" tertiary>
          	 +54 011 4022 2555
          </FooterBlockLink>
        
          <FooterBlockLink  to="/contacto" tertiary>
		        +54 011 6732 8625
          </FooterBlockLink>

	  <FooterBlockLink  to="/contacto" tertiary>
		soporte.dflow@softdelnoa.com.ar
          </FooterBlockLink>


        </FooterBlockContent>
        </FooterBlock >

    </FooterContent>
  </FooterWrapper>
);

Footer.propTypes = {
  guides: PropTypes.shape({
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        node: PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
          }).isRequired,
          fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired
    ),
  }).isRequired,
};

export default Footer;
