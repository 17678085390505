// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-screens-chapter-screen-index-js": () => import("./../src/components/screens/ChapterScreen/index.js" /* webpackChunkName: "component---src-components-screens-chapter-screen-index-js" */),
  "component---src-components-screens-guide-screen-index-js": () => import("./../src/components/screens/GuideScreen/index.js" /* webpackChunkName: "component---src-components-screens-guide-screen-index-js" */),
  "component---src-components-screens-guide-screen-markdown-js": () => import("./../src/components/screens/GuideScreen/markdown.js" /* webpackChunkName: "component---src-components-screens-guide-screen-markdown-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

