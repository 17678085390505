import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { styles } from '@storybook/design-system';

const { color } = styles;

const Img = styled.img`
  display: inline-block;
  vertical-align: middle;

  &&:hover {
 //   filter: saturate(5) hue-rotate(175deg);
  }

  .learnstorybook-text {
    fill: ${props => (props.isInverted ? color.lightest : color.darker)};
  }

  ${props =>
    props.isInverted &&
    `
    .learnstorybook-box {
      fill: ${color.lightest};
    }

    .learnstorybook-s {
      fill: transparent;
    }
  `}
`;


const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;

  .learnstorybook-text {
    fill: ${props => (props.isInverted ? color.lightest : color.darker)};
  }

  ${props =>
    props.isInverted &&
    `
    .learnstorybook-box {
      fill: ${color.lightest};
    }

    .learnstorybook-s {
      fill: transparent;
    }
  `}
`;

const Logo = ({ isInverted, ...rest }) => (
  <Svg width="148" height="20" viewBox="0 0 148 20" isInverted={isInverted} {...rest}>
    <g fill="none" fillRule="nonzero"> </g>
  </Svg>
);

Logo.propTypes = {
  isInverted: PropTypes.bool,
};

Logo.defaultProps = {
  isInverted: false,
};

export const ZLogo = ({ image, isInverted, ...rest }) => (
   <Img width="148" height="28" viewBox="0 0 148 28" src={image} isInverted={isInverted} {...rest}/>
);

ZLogo.propTypes = {
  isInverted: PropTypes.bool,
  image: PropTypes.text
};

ZLogo.defaultProps = {
  isInverted: false,
  src: "/icon-dflow.png",
};



export default Logo;
