import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  display: inline-block;
  vertical-align: middle;
`;

export default function Logo({ ...props }) {
  return (
    <Svg width="320px" height="79px" viewBox="0 0 320 79" {...props}>
      <path
        id="Text"
        fill="#333333"
        fillOpacity="0.9"
        d="M306.428,26.521c7.744,0,13.572,4.251,13.572,13.504v18.502h-7.744
      	l0.229-4.475c-2.303,3.357-5.367,5.147-10.121,5.147c-6.365,0-11.582-2.909-11.582-9.923c0-6.494,5.37-9.325,12.654-9.402h8.82
      	c0-5-2.301-7.088-6.44-7.088c-3.334,0-5.552,1.122-7.211,2.546l-5.594-3.142l-0.079-0.077c0.049-0.056,0.096-0.115,0.146-0.166
      	C295.9,28.757,299.715,26.521,306.428,26.521z M312.256,47.559v-2.461h-8.281c-3.375,0-5.6,1.194-5.6,3.957
      	c0,2.684,2.146,4.1,6.135,4.1C309.035,53.154,312.256,50.99,312.256,47.559z M121.994,48.888l6.2,3.483
      	c-3.049,4.129-8.016,6.829-13.64,6.829c-9.275,0-16.792-7.317-16.792-16.341c0-9.023,7.516-16.338,16.792-16.338
      	c5.615,0,10.568,2.688,13.62,6.802l-6.2,3.486c-1.782-2.061-4.445-3.375-7.42-3.375c-5.34,0-9.685,4.228-9.685,9.424
      	c0,5.195,4.344,9.426,9.685,9.426C117.541,52.285,120.215,50.963,121.994,48.888z M235.373,27.193h7.744l-0.152,5.372
      	c2.148-3.953,5.445-6.043,9.587-6.043c4.677,0,7.747,2.161,9.433,5.968c2.53-3.73,6.363-5.968,11.041-5.968
      	c7.592,0,12.113,4.925,12.113,12.906v19.101h-7.741V40.175c0-4.33-1.612-6.716-6.06-6.716c-4.521,0-7.209,2.687-7.209,7.91v17.158
      	h-7.741V40.175c0-4.33-1.537-6.716-5.983-6.716c-4.604,0-7.287,2.687-7.287,7.91v17.158h-7.744V27.193z M133.793,9.066h7.744v23.646
      	c2.072-3.877,5.908-6.191,10.742-6.191c7.127,0,12.038,4.925,12.038,13.432v18.575h-7.748V40.549c0-4.478-2.146-7.09-6.673-7.09
      	c-5.059,0-8.358,2.985-8.358,8.059v17.01h-7.744V9.066z M195.643,42.859c0-9.023,7.52-16.338,16.791-16.338
      	c9.279,0,16.793,7.315,16.793,16.338c0,9.023-7.514,16.341-16.793,16.341C203.162,59.2,195.643,51.883,195.643,42.859z
      	 M212.434,33.435c-5.338,0-9.684,4.228-9.684,9.424c0,5.195,4.346,9.426,9.684,9.426c5.346,0,9.691-4.23,9.691-9.426
      	C222.125,37.663,217.779,33.435,212.434,33.435z M172.014,27.193h7.749l-0.079,5.893c2.152-4.923,5.83-6.491,9.358-6.491
      	c1.65,0,2.948,0.27,4.056,0.725c0.437,0.178,1.348,0.604,2.234,1.25c0,0-1.072,1.299-2.018,2.88
      	c-0.949,1.582-1.588,3.277-1.588,3.277c-1.228-0.595-2.456-1.045-4.218-1.045c-4.602,0-7.746,2.612-7.746,9.627v15.219h-7.749
      	V27.193z"
      />
      <ellipse id="Oval" fill="#FC521F" cx="39.266" cy="39.2" rx="39.265" ry="39.2" />
      <g id="Logo">
        <path
          id="path-1_1_"
          fill="#FFFFFF"
          d="M47.643,57.228l-6.206-3.594l18.669-10.81c0.715-0.414,1.389-0.89,2.017-1.42
      		c2.427,3.468,2.665,8.036,0.502,11.796c-1.955,3.394-5.601,5.502-9.511,5.502C51.201,58.702,49.31,58.192,47.643,57.228z
      		 M46.148,59.824c0.716,0.416,1.463,0.765,2.236,1.045c-1.782,3.842-5.605,6.331-9.937,6.331c-6.045,0-10.967-4.935-10.967-10.999
      		V34.581l9.473,5.486v13.568c0,0.535,0.285,1.03,0.747,1.298L46.148,59.824z M23.809,58.7c-3.911,0-7.551-2.106-9.507-5.499
      		c-1.462-2.544-1.854-5.508-1.094-8.347s2.572-5.211,5.11-6.68l6.204-3.593l0.001,21.616c0,0.829,0.071,1.651,0.215,2.465
      		C24.428,58.688,24.118,58.7,23.809,58.7z M47.949,31.983l-9.469,5.483l-11.715-6.783c-0.23-0.133-0.488-0.201-0.749-0.201
      		c-0.256,0-0.514,0.068-0.746,0.201l-8.448,4.892c-0.716,0.413-1.392,0.887-2.02,1.42c-2.426-3.468-2.663-8.036-0.501-11.796
      		c1.956-3.394,5.598-5.503,9.511-5.503c1.911,0,3.803,0.512,5.471,1.477L47.949,31.983z M38.447,11.2
      		c6.049,0,10.967,4.935,10.967,11.001v7.187L30.75,18.581c-0.716-0.417-1.463-0.767-2.238-1.048
      		C30.293,13.69,34.119,11.2,38.447,11.2z M62.625,25.203c3.021,5.253,1.222,11.994-4.017,15.025L39.943,51.039v-10.97l11.714-6.784
      		c0.463-0.268,0.748-0.763,0.748-1.299v-9.784c0.001-0.827-0.074-1.65-0.215-2.461c0.307-0.027,0.616-0.04,0.926-0.04
      		C57.028,19.702,60.672,21.81,62.625,25.203z"
        />
      </g>
    </Svg>
  );
}
